import { QueryKeys } from "enums/UseQueryKeys";
import { useMutation, useQuery } from "react-query";
import { deluxBaseUrl, indexBaseUrl, sendRequest } from "utilities/http-api";
import {
    STATE,
    COUNTRIES,
    STATUS_FLAG,
    DOCUMENT_TYPE,
    DIARY_CATEGORY,
    REASONS,
    GET_EVENT_ASSOCIATED_CHECKLIST,
    CHECKLIST_RESPONSE_VERIFICATION,
    STIPULATION_REASON,
    GET_TENENT_CONFIG_INFORMATION,
    UNWIND_REASON,
} from "common/internalEndpointUrls";

export const useGetStates = () => {
    return useQuery(QueryKeys.GET_ALL_STATES, async () => {
        return sendRequest(indexBaseUrl)
            .get(`${STATE}`)
            .then((response) => {
                return response.data;
            });
    });
};

export const useGetCountries = () => {
    return useQuery(QueryKeys.GET_ALL_COUNTRIES, async () => {
        return sendRequest(indexBaseUrl)
            .get(`${COUNTRIES}`)
            .then((response) => {
                return response.data;
            });
    });
};

export const useGetConfiguredFlags = (reference_id) => {
    const key = [QueryKeys.GET_ALL_CONFIGURED_FLAGS, reference_id];
    return useQuery(
        key,
        async () => {
            return sendRequest(indexBaseUrl)
                .get(`${STATUS_FLAG}?`)
                .then((data) => {
                    return data;
                });
        },
        { refetchOnWindowFocus: false }
    );
};

export const useGetDocumentType = (): any => {
    const { data, isLoading, mutate } = useMutation((body: any) => {
        return sendRequest(indexBaseUrl).get(`${DOCUMENT_TYPE}/${body.is_stipulation}`);
    });
    return { data, isLoading, mutate };
};

export const useGetAllDiaryCatergory = () => {
    const key = [QueryKeys.GET_ALL_CONFIGURED_DIARY_CATEGORIES];
    return useQuery(
        key,
        async () => {
            return sendRequest(indexBaseUrl)
                .get(`${DIARY_CATEGORY}`)
                .then((data) => {
                    return data;
                });
        },
        { refetchOnWindowFocus: false }
    );
};
export const useGetConfiguredReasons = (): any => {
    const { data, isLoading, mutate } = useMutation(() => {
        return sendRequest(indexBaseUrl).get(`${REASONS}`);
    });
    return { data, isLoading, mutate };
};

export const useGetUnwindConfiguredReasons = (): any => {
    const { data, isLoading, mutate } = useMutation<any>((body: any) => {
        return sendRequest(indexBaseUrl)
            .get<any>(`${UNWIND_REASON}?reason_type=${body.reason_type}`)
    });
    return { data, isLoading, mutate };
};
    // tempoarary path change as per kamran for internal endpoint movement
export const useGetEventAssociatedChecklist = (): any => {
    const { data, isLoading, mutate } = useMutation<any>((body: any) => {
        return sendRequest(deluxBaseUrl)
            .post(`${GET_EVENT_ASSOCIATED_CHECKLIST}`, body)
            .then((response) => {
                return response.data;
            });
    });
    return { data, isLoading, mutate };
};

export const useChecklistResponseVerification = (): any => {
    const { data, isLoading, mutate } = useMutation<any>((body: any) => {
        return sendRequest(indexBaseUrl)
            .post(`${CHECKLIST_RESPONSE_VERIFICATION}`, body)
            .then((response) => {
                return response.data;
            });
    });
    return { data, isLoading, mutate };
};

export const useGetStipRejectReasons = (): any => {
    const { data, isLoading, mutate } = useMutation<any>((req: any) => {
        return sendRequest(indexBaseUrl)
            .get<any>(`${STIPULATION_REASON}/${req.stipName}/${req.rejectStatus}`)
            .then((response) => {
                return response.data;
            });
    });
    return { data, isLoading, mutate };
};

export const useGetTenantConfiguredInformation = () => {
    return useQuery(QueryKeys.GET_TENANT_INFORMATION, async () => {
        return sendRequest(indexBaseUrl)
            .get(`${GET_TENENT_CONFIG_INFORMATION}`)
            .then((response) => {
                return response.data;
            });
    });
};